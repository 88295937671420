import request from '@/utils/request'
// import qs from 'qs'

// export const baseUrl = 'http://192.168.0.188:9200'
// export const baseUrl = 'http://39.108.10.219'
export function filesAnon(param) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return request.post('/api-file/files-anon', param, config)
}
// 上传base64格式的文件
export function base64Upload(data) {
  return request({
    url: `/api-file/base64Upload`,
    method: 'post',
    data
  })
}

// 百度身份识图
export function baiduOcr(param) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return request.post('/api-file/files-anon/baiduOcr', param, config)
}
