<template>
  <div class="user-auth">
    <div class="upload">
      <div class="z">
        <van-uploader :max-size="5000 * 1024" :after-read="faceAfterRead" @oversize="onOversize">
          <div class="img"><img :src="form.faceIdentityImgBase64" alt=""></div>
          <div class="des">拍摄正面</div>
        </van-uploader>
      </div>
      <div class="f">
        <van-uploader :after-read="backAfterRead" @oversize="onOversize">
          <div class="img"><img :src="form.backIdentityImgBase64" alt=""></div>
          <div class="des">拍摄反面</div>
        </van-uploader>
      </div>
    </div>
    <div class="form">
      <div class="head">* 请核实身份证信息内容</div>
      <div class="group">
        <div class="title">姓名</div>
        <div class="input">
          <van-field
            v-model="form.realName"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">地址</div>
        <div class="input">
          <van-field
            v-model="form.address"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">身份证号码</div>
        <div class="input">
          <van-field
            v-model="form.idCard"
            maxlength="18"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">性别</div>
        <div class="input">
          <van-field
            v-model="form.sex"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">民族</div>
        <div class="input">
          <van-field
            v-model="form.nation"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">出生年月</div>
        <div class="input">
          <van-field
            v-model="form.birthday"
            readonly
            @click="goSelcetData('birthday')"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">起始日期</div>
        <div class="input">
          <van-field
            v-model="form.startTime"
            readonly
            @click="goSelcetData('startTime')"
          />
        </div>
      </div>
      <div class="group">
        <div class="title">截至日期</div>
        <div class="input">
          <van-field
            v-model="form.endTime"
            readonly
            @click="goSelcetData('endTime')"
          />
        </div>
      </div>
      <div class="bottom">
        <van-button
          round
          block
          type="info"
          color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)"
          @click="goSumbit"
        >
          立即认证
        </van-button>
      </div>
      <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="dataConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { parseTime, idTimeToDate } from '@/utils/index'
import { baiduOcr } from '@/api/file'
import { userAuthentication } from '@/api/user'
import faceIdentityImgBase64 from '@/assets/user/id_z.png'
import backIdentityImgBase64 from '@/assets/user/id_f.png'
export default {
  data() {
    return {
      form: {
        realName: '',
        address: '',
        idCard: '',
        sex: '',
        nation: '',
        birthday: '',
        startTime: '',
        endTime: '',
        faceIdentityImgBase64,
        backIdentityImgBase64
      },
      show: false,
      currentDate: '',
      currentDateKey: '',
      minDate: new Date('1970/1/1'),
      maxDate: ''
    }
  },
  mounted() {
    this.currentDate = new Date()
    const year = this.currentDate.getFullYear() * 1 + 70
    const month = this.currentDate.getMonth() * 1 + 1
    const day = this.currentDate.getDate()
    this.maxDate = new Date(`${year}/${month}/${day}`)
  },
  methods: {
    dataConfirm(time) {
      if (this.currentDateKey === 'birthday') {
        const timeline = new Date(time).getTime()
        const nowTimeline = new Date().getTime()
        console.log(timeline, nowTimeline)
        if ((nowTimeline - timeline) < 1000 * 60 * 60 * 24) {
          this.show = false
          return this.$toast('出生日期必须小于当前日期')
        }
      }
      this.form[this.currentDateKey] = parseTime(time, '{y}/{m}/{d}')
      this.show = false
    },
    goSelcetData(key) {
      this.currentDate = this.form[key] ? new Date(this.form[key]) : new Date()
      console.log(this.currentDate)
      this.currentDateKey = key
      this.show = true
    },
    onOversize() {
      this.$toast('图片大小不能超过5mb！')
    },
    async faceAfterRead(e) {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await this.goBaduOcr(e)
      loading.clear()
      if (res.idcard_number_type === -1) {
        return this.$toast('请上传正确的身份证照片！')
      } else {
        if (res.image_status === 'reversed_side') return this.$toast('请上传身份证正面！')
        this.form.faceIdentityImgBase64 = e.content
        this.form.address = res.words_result['住址'].words
        this.form.idCard = res.words_result['公民身份号码'].words
        this.form.birthday = idTimeToDate(res.words_result['出生'].words, '/')
        this.form.realName = res.words_result['姓名'].words
        this.form.sex = res.words_result['性别'].words
        this.form.nation = res.words_result['民族'].words
      }
    },
    async backAfterRead(e) {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await this.goBaduOcr(e)
      console.log(res, '1111111111111')
      loading.clear()
      if (res.idcard_number_type === -1) {
        return this.$toast('请上传正确的身份证照片！')
      } else {
        if (res.image_status === 'normal') return this.$toast('请上传身份证反面！')
        this.form.backIdentityImgBase64 = e.content
        this.form.startTime = idTimeToDate(res.words_result['签发日期'].words, '/')
        this.form.endTime = idTimeToDate(res.words_result['失效日期'].words, '/')
      }
    },
    async goBaduOcr(e) {
      console.log(e, '00000000000')
      const param = new FormData() // 创建form对象
      param.append('file', e.file) // 通过append向form对象添加数据
      const res = await baiduOcr(param)
      return JSON.parse(res.datas)
    },
    async goSumbit() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const params = { ...this.form }
      if (!params.realName) return this.$toast('请输入姓名！')
      if (!params.address) return this.$toast('请输入地址！')
      if (!params.idCard) return this.$toast('请输入身份证号码！')
      if (!params.sex) return this.$toast('请输入性别！')
      if (!params.nation) return this.$toast('请输入民族！')
      if (!params.birthday) return this.$toast('请输入出生日期！')
      if (!params.startTime) return this.$toast('请输入起始日期！')
      if (!params.endTime) return this.$toast('请输入截止日期！')
      params.birthday = params.birthday.replaceAll('/', '')
      params.startTime = params.startTime.replaceAll('/', '')
      params.endTime = params.endTime.replaceAll('/', '')
      params.sex = params.sex === '男' ? 1 : 0
      await userAuthentication(params)
      loading.clear()
      const _this = this
      this.$toast({
        message: '认证成功',
        type: 'success',
        duration: 2000,
        onClose() {
          _this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-auth {
    padding: 16px;
    .upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .z {
            width: 164px;
            img {
              width: 164px;
              height: 104px;
            }
        }
        .f {
            width: 164px;
            img {
              width: 164px;
              height: 104px;
            }
        }
        .z,.f {
          text-align: center;
          .des {
            padding-top: 10px;
            font-size: 14px;
            color: #999999;
          }
        }
    }
    .form {
      .head {
        padding-top: 30px;
        font-size: 12px;
        color: #FF7700;
      }
      .group {
        .title {
          padding: 16px 0 10px 0;
          font-size: 12px;
          color: #999999;
        }
        .input {
          margin: 0 -16px;
        }
      }
    }
    .bottom {
      padding: 0 40px 40px 40px;
    }
}
</style>
